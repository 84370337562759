<template>
  <div class="status_container">
    <!-- 缴费成功 -->
    <div class="content" v-if="isPay === 1">
      <div class="title">
        <van-icon name="passed" color="rgb(13, 190, 13)" size="50px" />
        <span>支付成功</span>
      </div>
      <div class="info">
        <div>诊疗卡号：{{ patCardNo }}</div>
        <div>就诊人：{{ patientName }}</div>
        <div>发票号：{{ receiptNum }}</div>
        <div style="white-space: pre-line;">{{ remark }}</div>
      </div>
      <div class="btn">
        <van-button type="info" @click="back"
          >返&nbsp;回&nbsp;首&nbsp;页</van-button
        >
        <van-button type="primary" @click="record"
          >查&nbsp;看&nbsp;记&nbsp;录</van-button
        >
      </div>
      <div class="qrCode">
        <img :src="'data:image/png;base64,' + qrCode" />
      </div>
      <div class="tip">可凭此二维码到自助机内扫描打印电子发票</div>
    </div>
    <!-- 已退款 -->
    <div class="content" v-if="isPay === 2">
      <div class="title">
        <van-icon name="warning-o" color="orange" size="50px" />
        <span>已退款</span>
      </div>
      <div class="info">
        <div>诊疗卡号：{{ patCardNo }}</div>
        <div>流水号：{{ hospitalOrderNum }}</div>
        <div>温馨提示：{{ remark }}</div>
      </div>
      <div class="btn">
        <van-button type="info" @click="back"
          >返&nbsp;回&nbsp;首&nbsp;页</van-button
        >
        <van-button type="primary" @click="record"
          >查&nbsp;看&nbsp;记&nbsp;录</van-button
        >
      </div>
    </div>
    <!-- 支付中 -->
    <div class="content" v-if="isPay === 0 || isPay === 7">
      <div class="title">
        <van-icon name="close" color="red" size="50px" />
        <span>支付异常</span>
      </div>
      <div class="info">
        <div>诊疗卡号：{{ patCardNo }}</div>
        <div>流水号：{{ hospitalOrderNum }}</div>
        <div>温馨提示：{{ remark }}</div>
      </div>
      <div class="btn" v-if="isPay === 7">
        <van-button type="info" @click="refresh" class="refresh"
          >刷&nbsp;&nbsp;新</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Status",
  data() {
    return {
      isPay: "",
      remark: "",
      outTradeNo: "",
      hospitalOrderNum: "",
      codeImg: "",
      patCardNo: "",
      id: "",
      qrCode: "",
      patientName: "",
      receiptNum: ""
    };
  },
  created() {
    this.outTradeNo = this.$route.query.outTradeNo;
    this.getPayStatus();
  },
  methods: {
    //查询支付状态
    async getPayStatus() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        outTradeNo: this.outTradeNo || window.localStorage.getItem("outTradeNo")
        // outTradeNo: "2211191457025377"
      });
      const { data: res } = await formPost(
        "/wxapp/pay/orderRecord/getOrderPayStatus",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.isPay = res.data.isPay;
        this.patCardNo = res.data.medicalCardNo;
        this.hospitalOrderNum = res.data.hospitalOrderNum;
        this.id = res.data.id;
        this.receiptNum = res.data.receiptNum;
        this.patientName = res.data.patientName;
        this.getInvoice();
        if (res.data.isPay === 1) {
          let remark = res.data.remark.replace(/,|，/g, "\n");
          this.remark = remark;
        } else if (res.data.isPay === 0) {
          this.remark = "支付异常，请到人工窗口办理";
        } else if (res.data.isPay === 2) {
          this.remark = res.data.remark;
        } else if (res.data.isPay === 7) {
          this.remark =
            "系统繁忙或网络异常，业务未能处理完成，请点击“刷新”按钮重试，或到人工窗口办理";
        }
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //查询电子发票详情
    async getInvoice() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/outpatient/viewInvoice", {
        agtOrdNum: this.outTradeNo,
        patCardNo: this.patCardNo
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.qrCode = res.data.invoiceQRCode;
      } else {
        this.$toast.fail(res.msg);
      }
    },
    //刷新重新调起支付
    async recommit() {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        id: this.id,
        outTradeNo: this.outTradeNo
      });
      const { data: res } = await formPost("/wxapp/pay/wxpay/recommit", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.isPay = 1;
        let remark = res.data.remarkreplace(/,|，/g, "\n");
        this.remark = remark;
        this.getInvoice();
      } else {
        this.$toast(res.msg);
      }
    },
    refresh() {
      this.recommit();
    },
    back() {
      this.$router.push({
        path: "/outpatient"
      });
    },
    record() {
      this.$router.push({
        path: "/order/outpatient",
        query: {
          patCardNo: this.patCardNo
        }
      });
    }
  }
};
</script>

<style scope>
.status_container {
  padding-bottom: 60px;
}

.status_container .content {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border: 1px solid #f5f5f5;
  box-shadow: darkgrey 10px 10px 30px 5px;
}

.status_container .content .title {
  position: relative;
  height: 180px;
  font-size: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 120px;
}

.status_container .content .info {
  width: 85%;
  font-size: 40px;
  line-height: 70px;
  margin: 0 auto;
}

.status_container .content .btn {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
  padding: 0 40px;
}

.status_container .content .btn .van-button {
  font-size: 36px;
}

.status_container .content .btn .refresh {
  width: 100%;
}

.status_container .content .qrCode {
  margin: 0 auto;
  width: 500px;
  height: 500px;
  margin-top: -50px;
}

.status_container .content .qrCode img {
  width: 100%;
  height: 100%;
}

.status_container .content .tip {
  font-size: 40px;
  padding: 0 30px;
  margin-bottom: 30px;
  margin-top: -30px;
}
</style>
